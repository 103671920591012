import React from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const DatenschutzPage = (props) => {
  const title = 'Datenschutzerklärung';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Datenschutzerklärung der Webseite von Coiffeur Cocoon."
      />

      <ContentSection>
        <h1>{title}</h1>

        <p>
          Mit dieser <strong>Datenschutzerklärung</strong> informieren wir,
          welche Personendaten wir im Zusammenhang mit unseren{' '}
          <strong>Aktivitäten und Tätigkeiten</strong> einschliesslich unserer{' '}
          <strong>
            <nobr>www.coiffeur-cocoon.ch</nobr>-Website
          </strong>{' '}
          bearbeiten. Wir informieren insbesondere, wofür, wie und wo wir welche
          Personendaten bearbeiten. Wir informieren ausserdem über die Rechte
          von Personen, deren Daten wir bearbeiten.
        </p>

        <p>
          Für einzelne oder zusätzliche Aktivitäten und Tätigkeiten können
          weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie
          Allgemeine Geschäftsbedingungen (AGB), Nutzungsbedingungen oder
          Teilnahmebedingungen gelten.
        </p>

        <h2 id="kontaktadressen" className="mt-30">
          <small>1. Kontaktadressen</small>
        </h2>

        <p>Verantwortung für die Bearbeitung von Personendaten:</p>

        <p>
          <strong id="verantwortlicher">Coiffeur Cocoon</strong>
          <br />
          Jasmin Sheila Brechbühl
          <br />
          Bernstrasse 15
          <br />
          3600 Thun
          <br />
          Schweiz
        </p>

        <p>
          <a href="mailto:info@coiffeur-cocoon.ch">info@coiffeur-cocoon.ch</a>
        </p>

        <p>
          Wir weisen darauf hin, wenn es im Einzelfall andere Verantwortliche
          für die Bearbeitung von Personendaten gibt.
        </p>

        <h2 id="begriffe-rechtsgrundlagen" className="mt-30">
          <small>2. Begriffe und Rechtsgrundlagen</small>
        </h2>

        <h3 id="begriffe" className="mt-30">
          <small>2.1 Begriffe</small>
        </h3>

        <p>
          <strong>Personendaten</strong> sind <em>alle</em> Angaben, die sich
          auf eine bestimmte oder bestimmbare natürliche Person beziehen. Eine{' '}
          <strong>betroffene Person</strong> ist eine Person, über die wir
          Personendaten bearbeiten.
        </p>

        <p>
          <strong>Bearbeiten</strong> umfasst <em>jeden</em> Umgang mit
          Personendaten, <em>unabhängig</em> von den angewandten Mitteln und
          Verfahren, beispielsweise das Abfragen, Abgleichen, Anpassen,
          Archivieren, Aufbewahren, Auslesen, Bekanntgeben, Beschaffen,
          Erfassen, Erheben, Löschen, Offenlegen, Ordnen, Organisieren,
          Speichern, Verändern, Verbreiten, Verknüpfen, Vernichten und Verwenden
          von Personendaten.
        </p>

        <h3 id="rechtsgrundlagen" className="mt-30">
          <small>2.2 Rechtsgrundlagen</small>
        </h3>

        <p>
          Wir bearbeiten Personendaten im Einklang mit dem schweizerischen
          Datenschutzrecht wie insbesondere dem{' '}
          <a
            href="https://www.fedlex.admin.ch/eli/oc/2022/491/de"
            rel="nofollow noopener noreferrer"
            target="_blank"
            id="90717"
          >
            Bundesgesetz über den Datenschutz
          </a>{' '}
          (Datenschutzgesetz, DSG) und der{' '}
          <a
            href="https://www.fedlex.admin.ch/eli/oc/2022/568/de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Verordnung über den Datenschutz
          </a>{' '}
          (Datenschutzverordnung, DSV).
        </p>

        <h2 id="art-umfang-zweck" className="mt-30">
          <small>3. Art, Umfang und Zweck</small>
        </h2>

        <p>
          Wir bearbeiten jene Personendaten, die <em>erforderlich</em> sind, um
          unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher
          und zuverlässig ausüben zu können. Solche Personendaten können
          insbesondere in die Kategorien von Bestandes- und Kontaktdaten,
          Browser- und Gerätedaten, Inhaltsdaten, Meta- bzw. Randdaten und
          Nutzungsdaten, Standortdaten, Verkaufsdaten sowie Vertrags- und
          Zahlungsdaten fallen.
        </p>

        <p>
          Wir bearbeiten Personendaten während jener <em>Dauer</em>, die für den
          jeweiligen Zweck bzw. die jeweiligen Zwecke oder gesetzlich
          erforderlich ist. Personendaten, deren Bearbeitung nicht mehr
          erforderlich ist, werden anonymisiert oder gelöscht.
        </p>

        <p>
          Wir können Personendaten <em>durch Dritte</em> bearbeiten lassen. Wir
          können Personendaten gemeinsam mit Dritten bearbeiten oder an Dritte
          übermitteln. Bei solchen Dritten handelt es sich insbesondere um
          spezialisierte Anbieter, deren Leistungen wir in Anspruch nehmen. Wir
          gewährleisten auch bei solchen Dritten den Datenschutz.
        </p>

        <p>
          Wir bearbeiten Personendaten nur nach Einwilligung der betroffenen
          Person, es sei denn, die Bearbeitung ist aus anderen rechtlichen
          Gründen zulässig. Die Bearbeitung ohne Einwilligung kann
          beispielsweise zulässig sein zur Erfüllung eines Vertrages mit der
          betroffenen Person und für entsprechende vorvertragliche Massnahmen,
          um unsere überwiegenden berechtigten Interessen zu wahren, weil die
          Bearbeitung aus den Umständen ersichtlich ist oder nach vorgängiger
          Information.
        </p>

        <p>
          In diesem Rahmen bearbeiten wir insbesondere Angaben, die eine
          betroffene Person bei der Kontaktaufnahme&nbsp;– beispielsweise per
          Briefpost, E-Mail, Instant Messaging, Kontaktformular, Social Media
          oder Telefon&nbsp;– oder bei der Registrierung für ein Nutzerkonto{' '}
          <em>freiwillig</em> an uns übermittelt. Wir können solche Angaben
          beispielsweise in einem Adressbuch, in einem
          Customer-Relationship-Management-System (CRM-System) oder mit
          vergleichbaren Hilfsmitteln speichern. Wenn wir Daten über andere
          Personen übermittelt erhalten, sind die übermittelnden Personen
          verpflichtet, den Datenschutz gegenüber diesen Personen zu
          gewährleisten sowie die Richtigkeit dieser Personendaten
          sicherzustellen.
        </p>

        <p>
          Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten,
          aus öffentlich zugänglichen Quellen beschaffen oder bei der Ausübung
          unserer Aktivitäten und Tätigkeiten erheben, sofern und soweit eine
          solche Bearbeitung aus rechtlichen Gründen zulässig ist.
        </p>

        <h2 id="bewerbungen" className="mt-30">
          <small>4. Bewerbungen</small>
        </h2>

        <p>
          Wir bearbeiten Personendaten über Bewerberinnen und Bewerber, soweit
          sie für die Einschätzung der Eignung für ein Arbeitsverhältnis oder
          für die spätere Durchführung eines Arbeitsvertrages erforderlich sind.
          Die erforderlichen Personendaten ergeben sich insbesondere aus den
          abgefragten Angaben, beispielsweise im Rahmen einer
          Stellenausschreibung. Wir bearbeiten ferner jene Personendaten, die
          Bewerberinnen und Bewerber <em>freiwillig</em> mitteilen oder
          veröffentlichen, insbesondere als Teil von Anschreiben, Lebenslauf und
          sonstigen Bewerbungsunterlagen sowie von Online-Profilen.
        </p>

        <h2 id="ausland" className="mt-30">
          <small>5. Personendaten im Ausland</small>
        </h2>

        <p>
          Wir bearbeiten Personendaten <em>grundsätzlich</em> in der Schweiz.
          Wir können Personendaten aber auch in andere Staaten bekanntgeben bzw.
          exportieren, insbesondere um sie dort zu bearbeiten oder bearbeiten zu
          lassen.
        </p>

        <p>
          Wir können Personendaten in alle{' '}
          <a
            href="https://de.wikipedia.org/wiki/Liste_der_Staaten_der_Erde#Tabelle"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Staaten und Territorien auf der Erde
          </a>{' '}
          sowie anderswo im{' '}
          <a
            href="https://www.datenschutzpartner.ch/2022/04/23/datenschutzerklaerung-daten-export-universum-mond/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Universum
          </a>{' '}
          bekanntgeben, sofern das dortige Recht gemäss{' '}
          <a
            href="https://steigerlegal.ch/2022/09/10/ndsg-schweiz-staatenliste/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Beschluss des Schweizerischen Bundesrates
          </a>{' '}
          einen angemessenen Datenschutz gewährleistet.{' '}
        </p>

        <p>
          Wir können Personendaten in Staaten, deren Recht keinen angemessenen
          Datenschutz gewährleistet, bekanntgeben, sofern aus anderen Gründen
          ein geeigneter Datenschutz gewährleistet ist. Ein geeigneter
          Datenschutz kann beispielsweise durch entsprechende vertragliche
          Vereinbarungen, auf Grundlage von Standard­datenschutzklauseln oder
          mit anderen geeigneten Garantien gewährleistet sein. Ausnahmsweise
          können wir Personendaten in Staaten ohne angemessenen oder geeigneten
          Datenschutz exportieren, wenn dafür die besonderen
          datenschutz­rechtlichen Voraussetzungen erfüllt sind, beispielsweise
          die ausdrückliche Einwilligung der betroffenen Personen oder ein
          unmittelbarer Zusammenhang mit dem Abschluss oder der Abwicklung eines
          Vertrages. Wir geben betroffenen Personen auf Nachfrage gerne Auskunft
          über allfällige Garantien oder liefern eine Kopie von Garantien.
        </p>

        <h2 id="rechte" className="mt-30">
          <small>6. Rechte von betroffenen Personen</small>
        </h2>

        <h3 id="ansprueche" className="mt-30">
          <small>6.1 Datenschutzrechtliche Ansprüche</small>
        </h3>

        <p>
          Wir gewähren betroffenen Personen sämtliche Ansprüche gemäss dem
          anwendbaren Datenschutzrecht. Betroffene Personen verfügen
          insbesondere über folgende Rechte:
        </p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>Auskunft:</strong> Betroffene Personen können Auskunft
            verlangen, ob wir Personendaten über sie bearbeiten, und falls ja,
            um welche Personendaten es sich handelt. Betroffene Personen
            erhalten ferner jene Informationen, die erforderlich sind, um ihre
            datenschutzrechtlichen Ansprüche geltend zu machen und Transparenz
            zu gewährleisten. Dazu zählen die bearbeiteten Personendaten als
            solche, aber unter anderem auch Angaben zum Bearbeitungszweck, zur
            Dauer der Aufbewahrung, zu einer allfälligen Bekanntgabe bzw. einem
            allfälligen Export von Daten in andere Staaten und zur Herkunft der
            Personendaten.
          </li>
          <li>
            <strong>Berichtigung und Einschränkung:</strong> Betroffene Personen
            können unrichtige Personendaten berichtigen, unvollständige Daten
            vervollständigen und die Bearbeitung ihrer Daten einschränken
            lassen.
          </li>
          <li>
            <strong>Löschung und Widerspruch:</strong> Betroffene Personen
            können Personendaten löschen lassen («Recht auf Vergessen») und der
            Bearbeitung ihrer Daten mit Wirkung für die Zukunft widersprechen.
          </li>
          <li>
            <strong>Datenherausgabe und Datenübertragung:</strong> Betroffene
            Personen können die Herausgabe von Personendaten oder die
            Übertragung ihrer Daten an einen anderen Verantwortlichen verlangen.
          </li>
        </ul>

        <p>
          Wir können die Ausübung der Rechte von betroffenen Personen im
          rechtlich zulässigen Rahmen aufschieben, einschränken oder verweigern.
          Wir können betroffene Personen auf allenfalls zu erfüllende
          Voraussetzungen für die Ausübung ihrer datenschutzrechtlichen
          Ansprüche hinweisen. Wir können beispielsweise die Auskunft mit
          Verweis auf Geschäftsgeheimnisse oder den Schutz anderer Personen ganz
          oder teilweise verweigern. Wir können beispielsweise auch die Löschung
          von Personendaten mit Verweis auf gesetzliche Aufbewahrungspflichten
          ganz oder teilweise verweigern.
        </p>

        <p>
          Wir können für die Ausübung der Rechte <em>ausnahmsweise</em> Kosten
          vorsehen. Wir informieren betroffene Personen vorgängig über
          allfällige Kosten.
        </p>

        <p>
          Wir sind verpflichtet, betroffene Personen, die Auskunft verlangen
          oder andere Rechte geltend machen, mit angemessenen Massnahmen zu
          identifizieren. Betroffene Personen sind zur Mitwirkung verpflichtet.
        </p>

        <h3 id="beschwerderecht" className="mt-30">
          <small>6.2 Recht auf Beschwerde</small>
        </h3>

        <p>
          Betroffene Personen haben das Recht, ihre datenschutzrechtlichen
          Ansprüche auf dem Rechtsweg durchzusetzen oder Beschwerde bei einer
          zuständigen Datenschutz-Aufsichtsbehörde zu erheben.
        </p>

        <p>
          Datenschutz-Aufsichtsbehörde für private Verantwortliche und
          Bundesorgane in der Schweiz ist der{' '}
          <a
            href="https://www.edoeb.admin.ch/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte
          </a>
          &nbsp;(EDÖB).
        </p>

        <h2 id="sicherheit" className="mt-30">
          <small>7. Datensicherheit</small>
        </h2>

        <p>
          Wir treffen geeignete technische und organisatorische Massnahmen, um
          eine dem jeweiligen Risiko angemessene Datensicherheit zu
          gewährleisten. Wir können aber keine absolute Datensicherheit
          gewährleisten.
        </p>

        <p>
          Der Zugriff auf unsere Website erfolgt mittels
          Transportverschlüsselung (SSL&nbsp;/ TLS, insbesondere mit dem
          Hypertext Transfer Protocol Secure, abgekürzt HTTPS). Die meisten
          Browser kennzeichnen Transportverschlüsselung mit einem
          Vorhängeschloss in der Adressleiste.
        </p>

        <p>
          Unsere digitale Kommunikation unterliegt&nbsp;– wie{' '}
          <em>grundsätzlich</em> jede digitale Kommunikation&nbsp;– der
          Massenüberwachung ohne Anlass und Verdacht sowie sonstiger Überwachung
          durch Sicherheitsbehörden in der Schweiz, im übrigen Europa, in den
          Vereinigten Staaten von Amerika (USA) und in anderen Staaten. Wir
          können keinen direkten Einfluss auf die entsprechende Bearbeitung von
          Personendaten durch Geheimdienste, Polizeistellen und andere
          Sicherheitsbehörden nehmen.
        </p>

        <h2 id="website" className="mt-30">
          <small>8. Nutzung der Website</small>
        </h2>

        <h3 id="cookies" className="mt-30">
          <small>8.1 Cookies</small>
        </h3>

        <p>
          Wir können Cookies verwenden. Bei Cookies&nbsp;– eigenen Cookies
          (First-Party-Cookies) als auch Cookies von Dritten, deren Dienste wir
          nutzen (Third-Party-Cookies)&nbsp;– handelt es sich um Daten, die im
          Browser gespeichert werden. Solche gespeicherten Daten müssen nicht
          auf traditionelle Cookies in Textform beschränkt sein.
        </p>

        <p>
          Cookies können im Browser temporär als «Session Cookies» oder für
          einen bestimmten Zeitraum als sogenannte permanente Cookies
          gespeichert werden. «Session Cookies» werden automatisch gelöscht,
          wenn der Browser geschlossen wird. Permanente Cookies haben eine
          bestimmte Speicherdauer. Cookies ermöglichen insbesondere, einen
          Browser beim nächsten Besuch unserer Website wiederzuerkennen und
          dadurch beispielsweise die Reichweite unserer Website zu messen.
          Permanente Cookies können aber beispielsweise auch für
          Online-Marketing verwendet werden.
        </p>

        <p>
          Cookies können in den Browser-Einstellungen jederzeit ganz oder
          teilweise deaktiviert sowie gelöscht werden. Ohne Cookies steht unsere
          Website allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir
          ersuchen&nbsp;– mindestens sofern und soweit erforderlich&nbsp;– aktiv
          um die ausdrückliche Einwilligung in die Verwendung von Cookies.
        </p>

        <p>
          Bei Cookies, die für die Erfolgs- und Reichweitenmessung oder für
          Werbung verwendet werden, ist für zahlreiche Dienste ein allgemeiner
          Widerspruch («Opt-out») über die{' '}
          <a
            href="https://youradchoices.ca/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            AdChoices
          </a>{' '}
          (Digital Advertising Alliance of Canada), die{' '}
          <a
            href="https://optout.networkadvertising.org/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Network Advertising Initiative
          </a>{' '}
          (NAI),{' '}
          <a
            href="https://optout.aboutads.info/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            YourAdChoices
          </a>{' '}
          (Digital Advertising Alliance) oder{' '}
          <a
            href="https://www.youronlinechoices.com/ch-de/praferenzmanagement"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Your Online Choices
          </a>{' '}
          (European Interactive Digital Advertising Alliance, EDAA) möglich.
        </p>

        <h3 id="logdateien" className="mt-30">
          <small>8.2 Server-Logdateien</small>
        </h3>

        <p>
          Wir können für jeden Zugriff auf unsere Website nachfolgende Angaben
          erfassen, sofern diese von Ihrem Browser an unsere
          Server-Infrastruktur übermittelt werden oder von unserem Webserver
          ermittelt werden können: Datum und Zeit einschliesslich Zeitzone,
          Internet Protocol (IP)-Adresse, Zugriffsstatus (HTTP-Statuscode),
          Betriebssystem einschliesslich Benutzeroberfläche und Version, Browser
          einschliesslich Sprache und Version, aufgerufene einzelne Unter-Seite
          unserer Website einschliesslich übertragener Datenmenge, zuletzt im
          gleichen Browser-Fenster aufgerufene Webseite (Referer bzw. Referrer).
        </p>

        <p>
          Wir speichern solche Angaben, die auch Personendaten darstellen
          können, in Server-Logdateien. Die Angaben sind erforderlich, um unsere
          Website dauerhaft, nutzerfreundlich und zuverlässig bereitstellen
          sowie um die Datensicherheit und damit insbesondere den Schutz von
          Personendaten sicherstellen zu können – auch durch Dritte oder mit
          Hilfe von Dritten.
        </p>

        <h3 id="zaehlpixel" className="mt-30">
          <small>8.3 Zählpixel</small>
        </h3>

        <p>
          Wir können Zählpixel auf unserer Website verwenden. Zählpixel werden
          auch als Web-Beacons bezeichnet. Bei Zählpixeln&nbsp;– auch von
          Dritten, deren Dienste wir nutzen&nbsp;– handelt es sich um kleine,
          üblicherweise nicht sichtbare Bilder, die beim Besuch unserer Website
          automatisch abgerufen werden. Mit Zählpixeln können die gleichen
          Angaben wie in Server-Logdateien erfasst werden.
        </p>

        <h2 id="mitteilungen" className="mt-30">
          <small>9. Benachrichtigungen und Mitteilungen</small>
        </h2>

        <p>
          Wir versenden Benachrichtigungen und Mitteilungen per E-Mail und über
          andere Kommunikationskanäle wie beispielsweise Instant Messaging oder
          SMS.
        </p>

        <h3 id="mitteilungen-statistik" className="mt-30">
          <small>9.1 Erfolgs- und Reichweitenmessung</small>
        </h3>

        <p>
          Benachrichtigungen und Mitteilungen können Weblinks oder Zählpixel
          enthalten, die erfassen, ob eine einzelne Mitteilung geöffnet wurde
          und welche Weblinks dabei angeklickt wurden. Solche Weblinks und
          Zählpixel können die Nutzung von Benachrichtigungen und Mitteilungen
          auch personenbezogen erfassen. Wir benötigen diese statistische
          Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung, um
          Benachrichtigungen und Mitteilungen aufgrund der Bedürfnisse und
          Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und
          nutzerfreundlich sowie dauerhaft, sicher und zuverlässig versenden zu
          können.
        </p>

        <h3 id="mitteilungen-einwilligung" className="mt-30">
          <small>9.2 Einwilligung und Widerspruch</small>
        </h3>

        <p>
          Sie müssen <em>grundsätzlich</em> in die Verwendung Ihrer
          E-Mail-Adresse und Ihrer sonstigen Kontaktadressen ausdrücklich
          einwilligen, es sei denn, die Verwendung ist aus anderen rechtlichen
          Gründen zulässig. Für eine allfällige Einwilligung verwenden wir nach
          Möglichkeit das «Double Opt-in»-Verfahren, das heisst, Sie erhalten
          eine E-Mail mit einem Weblink, den Sie zur Bestätigung anklicken
          müssen, damit kein Missbrauch durch unberechtigte Dritte erfolgen
          kann. Wir können solche Einwilligungen einschliesslich Internet
          Protocol (IP)-Adresse sowie Datum und Zeit aus Beweis- und
          Sicherheitsgründen protokollieren.
        </p>

        <p>
          Sie können <em>grundsätzlich</em> dem Erhalt von Benachrichtigungen
          und Mitteilungen wie beispielsweise Newslettern jederzeit
          widersprechen. Mit einem solchen Widerspruch können Sie gleichzeitig
          der statistischen Erfassung der Nutzung für die Erfolgs- und
          Reichweitenmessung widersprechen. Vorbehalten bleiben erforderliche
          Benachrichtigungen und Mitteilungen im Zusammenhang mit unseren
          Aktivitäten und Tätigkeiten.
        </p>

        <h3 id="mitteilungen-dienstleister" className="mt-30">
          <small>
            9.3 Dienstleister für Benachrichtigungen und Mitteilungen
          </small>
        </h3>

        <p>
          Wir versenden Benachrichtigungen und Mitteilungen mit Hilfe von
          spezialisierten Dienstleistern.
        </p>

        <p>Wir nutzen insbesondere:</p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>
              <a
                href="https://www.brevo.com/de/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Brevo:
              </a>
            </strong>{' '}
            Aufbau und Pflege von Kundenbeziehungen insbesondere per E-Mail und
            Instant Messaging; Anbieterin: Sendinblue&nbsp;GmbH (Deutschland);
            Angaben zum Datenschutz:{' '}
            <a
              href="https://www.brevo.com/de/legal/privacypolicy/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Datenschutzerklärung
            </a>
            ,{' '}
            <a
              href="https://www.brevo.com/de/datenschutz-uebersicht/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Datenschutz und Datensicherheit»
            </a>
            ,{' '}
            <a
              href="https://www.brevo.com/de/sicherheit/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Sicherheit und Datenschutz»
            </a>
            .
          </li>
        </ul>

        <h2 id="social-media" className="mt-30">
          <small>10. Social Media</small>
        </h2>

        <p>
          Wir sind auf Social Media-Plattformen und anderen Online-Plattformen
          präsent, um mit interessierten Personen kommunizieren sowie über
          unsere Aktivitäten und Tätigkeiten informieren zu können. Im
          Zusammenhang mit solchen Plattformen können Personendaten auch
          ausserhalb der Schweiz bearbeitet werden.
        </p>

        <p>
          Es gelten jeweils auch die Allgemeinen Geschäftsbedingungen (AGB) und
          Nutzungsbedingungen sowie Datenschutzerklärungen und sonstigen
          Bestimmungen der einzelnen Betreiber solcher Plattformen. Diese
          Bestimmungen informieren insbesondere über die Rechte von betroffenen
          Personen direkt gegenüber der jeweiligen Plattform, wozu
          beispielsweise das Recht auf Auskunft zählt.
        </p>

        <h2 id="dienste" className="mt-30">
          <small>11. Dienste von Dritten</small>
        </h2>

        <p>
          Wir nutzen Dienste von spezialisierten Dritten, um unsere Aktivitäten
          und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und zuverlässig
          ausüben zu können. Mit solchen Diensten können wir unter anderem
          Funktionen und Inhalte in unsere Website einbetten. Bei einer solchen
          Einbettung erfassen die genutzten Dienste aus technisch zwingenden
          Gründen mindestens zeitweilig die Internet Protocol&nbsp;(IP)-Adressen
          der Nutzerinnen und Nutzer.
        </p>

        <p>
          Für erforderliche sicherheitsrelevante, statistische und technische
          Zwecke können Dritte, deren Dienste wir nutzen, Daten im Zusammenhang
          mit unseren Aktivitäten und Tätigkeiten aggregiert, anonymisiert oder
          pseudonymisiert bearbeiten. Es handelt sich beispielsweise um
          Leistungs- oder Nutzungsdaten, um den jeweiligen Dienst anbieten zu
          können.
        </p>

        <h3 id="infrastruktur" className="mt-30">
          <small>11.1 Digitale Infrastruktur</small>
        </h3>

        <p>
          Wir nutzen Dienste von spezialisierten Dritten, um benötigte digitale
          Infrastruktur im Zusammenhang mit unseren Aktivitäten und Tätigkeiten
          in Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und
          Speicherdienste von ausgewählten Anbietern.
        </p>

        <p>Wir nutzen insbesondere:</p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>
              <a
                href="https://www.cyon.ch/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Cyon:
              </a>
            </strong>{' '}
            Hosting; Anbieterin: cyon&nbsp;GmbH (Schweiz); Angaben zum
            Datenschutz:{' '}
            <a
              href="https://www.cyon.ch/ueber-cyon/datenschutz"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Datenschutz»
            </a>
            ,{' '}
            <a
              href="https://www.cyon.ch/legal/datenschutzerklaerung"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Datenschutzerklärung
            </a>
            .
          </li>
        </ul>

        <h3 id="kontaktmoeglichkeiten" className="mt-30">
          <small>11.2 Kontaktmöglichkeiten</small>
        </h3>

        <p>
          Wir nutzen Dienste von ausgewählten Anbietern, um mit Dritten wie
          beispielsweise potenziellen sowie bestehenden Kundinnen und Kunden
          besser kommunizieren zu können.
        </p>

        <p>Wir nutzen insbesondere:</p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>
              <a
                href="https://www.klara.ch/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                KLARA:
              </a>
            </strong>{' '}
            Customer-Relationship-Management (CRM); Anbieterin: KLARA Business
            AG (Schweiz); Angaben zum Datenschutz:{' '}
            <a
              href="https://www.klara.ch/datenschutz"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Datenschutz»
            </a>
            .
          </li>
        </ul>

        <h3 id="terminplanung" className="mt-30">
          <small>11.3 Terminplanung</small>
        </h3>

        <p>
          Wir nutzen Dienste von spezialisierten Dritten, um Termine online
          vereinbaren zu können, beispielsweise für Besprechungen. Es gelten
          ergänzend zu dieser Datenschutzerklärung jeweils auch allenfalls
          direkt ersichtliche Bedingungen der genutzten Dienste wie
          beispielsweise Nutzungsbedingungen oder Datenschutzerklärungen.
        </p>

        <p>Wir nutzen insbesondere:</p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>
              <a
                href="https://calenso.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Calenso:
              </a>
            </strong>{' '}
            Online-Terminplanung; Anbieterin: Calenso&nbsp;AG (Schweiz); Angaben
            zum Datenschutz:{' '}
            <a
              href="https://calenso.com/datenschutz"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Datenschutz»
            </a>
            ,{' '}
            <a
              href="https://calenso.com/agb"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Allgemeine Geschäftsbedingungen (AGB)
            </a>
            .
          </li>
        </ul>

        <h2 id="erfolgs-und-reichweitenmessung" className="mt-30">
          <small>12. Erfolgs- und Reichweitenmessung</small>
        </h2>

        <p>
          Wir versuchen zu ermitteln, wie unser Online-Angebot genutzt wird. In
          diesem Rahmen können wir beispielsweise den Erfolg und die Reichweite
          unserer Aktivitäten und Tätigkeiten sowie die Wirkung von Verlinkungen
          Dritter auf unsere Website messen. Wir können aber beispielsweise auch
          ausprobieren und vergleichen, wie unterschiedliche Teile oder
          Versionen unseres Online-Angebotes genutzt werden
          («A/B-Test»-Methode). Aufgrund der Ergebnisse der Erfolgs- und
          Reichweitenmessung können wir insbesondere Fehler beheben, beliebte
          Inhalte stärken oder Verbesserungen an unserem Online-Angebot
          vornehmen.
        </p>

        <p>
          Für die Erfolgs- und Reichweitenmessung werden in den meisten Fällen
          die Internet Protocol&nbsp;(IP)-Adressen von einzelnen Nutzerinnen und
          Nutzern gespeichert. IP-Adressen werden in diesem Fall{' '}
          <em>grundsätzlich</em> gekürzt («IP-Masking»), um durch die
          entsprechende Pseudonymisierung dem Grundsatz der Datensparsamkeit zu
          folgen.
        </p>

        <p>
          Bei der Erfolgs- und Reichweitenmessung können Cookies zum Einsatz
          kommen und Nutzerprofile erstellt werden. Allenfalls erstellte
          Nutzerprofile umfassen beispielsweise die besuchten einzelnen Seiten
          oder betrachteten Inhalte auf unserer Website, Angaben zur Grösse von
          Bildschirm oder Browser-Fenster und den&nbsp;– zumindest
          ungefähren&nbsp;– Standort. <em>Grundsätzlich</em> werden allfällige
          Nutzerprofile ausschliesslich pseudonymisiert erstellt und nicht für
          die Identifizierung einzelner Nutzerinnen und Nutzer verwendet.
          Einzelne Dienste von Dritten, bei denen Nutzerinnen oder Nutzer
          angemeldet sind, können die Nutzung unseres Online-Angebotes
          allenfalls dem Nutzerkonto oder Nutzerprofil beim jeweiligen Dienst
          zuordnen.
        </p>

        <p>Wir nutzen insbesondere:</p>

        <ul className="list bullets compact mb-30">
          <li>
            <strong>
              <a
                href="https://plausible.io/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Plausible Analytics:
              </a>
            </strong>{' '}
            Erfolgs- und Reichweitenmessung; Anbieterin: Plausible
            Insights&nbsp;OÜ (Estland); Angaben zum Datenschutz: <em>Keine</em>{' '}
            Bearbeitung von Personendaten und <em>keine</em> Verwendung von
            Cookies,{' '}
            <a
              href="https://plausible.io/privacy"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Datenschutzerklärung
            </a>
            ,{' '}
            <a
              href="https://plausible.io/data-policy"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Daten-Richtlinie» («Data Policy»)
            </a>
            ,{' '}
            <a
              href="https://plausible.io/about"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Über Plausible Analytics» («About Plausible Analytics»)
            </a>
            ,{' '}
            <a
              href="https://plausible.io/privacy-focused-web-analytics"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              «Google Analytics-Alternative mit Datenschutz-Fokus»
              («Privacy-focused Google Analytics Alternative»)
            </a>
            .
          </li>
        </ul>

        <h2 id="schlussbestimmungen" className="mt-30">
          <small>13. Schlussbestimmungen</small>
        </h2>

        <p>
          Wir haben diese Datenschutzerklärung mit dem{' '}
          <a
            href="https://www.datenschutzpartner.ch/angebot-datenschutz-generator/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutz-Generator
          </a>{' '}
          von{' '}
          <a
            href="https://datenschutzpartner.ch/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzpartner
          </a>{' '}
          erstellt.
        </p>

        <p>
          Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen.
          Wir werden über solche Anpassungen und Ergänzungen in geeigneter Form
          informieren, insbesondere durch Veröffentlichung der jeweils aktuellen
          Datenschutzerklärung auf unserer Website.
        </p>
      </ContentSection>
    </Layout>
  );
};

export default DatenschutzPage;
