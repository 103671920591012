import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import PortfolioItem from '../components/portfolio-item';
import PortfolioSection from '../components/portfolio-section';
import Team from '../components/team';

const UeberUnsPage = (props) => {
  const title = 'Über uns';

  const { images } = useStaticQuery(
    graphql`
      {
        images: allFile(filter: { relativeDirectory: { eq: "ueber-uns" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Coiffeur Cocoon steht am Stadtrand von Thun und ist sehr gut erreichbar. Der Salon ist räumlich unterteilt in die Bereiche Hair und Make-up."
      />

      <IntroText title={title}>
        Mit dem Coiffeur Cocoon habe ich, Jasmin, mir den grossen Traum meines
        eigenen Beauty-Salons erfüllt. Ich bin unheimlich stolz und voller
        Freude, meine Leidenschaft jeden Tag aufs Neue zum Ausdruck bringen zu
        dürfen - mit den besten Kund*innen. Mit Anja habe ich eine sehr
        wertvolle Partnerin gefunden, die mich und mein Konzept voller
        Begeisterung mitträgt.
      </IntroText>

      <Team />

      <PortfolioSection>
        <PortfolioItem
          title="Coiffeur Cocoon"
          image={getImage(
            images.edges.find((e) => e.node.name === 'aussen').node
          )}
        >
          <p>
            Im September 2020 feierte Coiffeur Cocoon bereits sein 5-jähriges
            Jubiläum. Der Salon steht am Stadtrand von Thun und ist sehr gut
            erreichbar, sowohl mit dem Auto (kostenlose Parkplätze direkt vor
            dem Geschäft vorhanden) als auch mit dem öffentlichen Verkehr. Der
            Salon ist räumlich unterteilt in die Bereiche <i>Hair</i> und{' '}
            <i>Make-up</i>, sodass die unterschiedlichen Anforderungen an die
            Umgebung optimal ausgenutzt werden können.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Hair"
          image={getImage(
            images.edges.find((e) => e.node.name === 'hair').node
          )}
          right={true}
        >
          <p>
            Im oberen Teil stehen drei Coiffeur-Stühle und ein Waschplatz zur
            Verfügung. Durch das grosse Fenster und die Spiegel kommt viel
            Tageslicht in den Raum und bietet eine angenehme Atmosphäre, um die
            Zeit beim Coiffeur geniessen zu können.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Make-up"
          image={getImage(
            images.edges.find((e) => e.node.name === 'make-up').node
          )}
        >
          <p>
            Den zweiten Teil des Salons verwenden wir zum Schminken unserer
            Kund*innen. Der Schminkbereich und die Lampen zur
            Tageslichtsimulation ermöglichen ein optimales Ergebnis.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Für die Kleinen"
          image={getImage(
            images.edges.find((e) => e.node.name === 'spieltruhe').node
          )}
          right={true}
        >
          <p>
            Wenn die Grossen sich verschönern lassen, sollen die Kleinen nicht
            zu kurz kommen. Wir haben eine Spieltruhe mit vielen Spielsachen,
            damit der Aufenthalt auch für den Nachwuchs so angenehm wie möglich
            wird.
          </p>
        </PortfolioItem>
      </PortfolioSection>
    </Layout>
  );
};

export default UeberUnsPage;
