import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Team = () => {
  return (
    <section className="section-wrap-sm from-blog team bg-light">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <div className="entry-item card">
              <div className="entry-img">
                <StaticImage
                  src="./../images/team/jasmin.jpg"
                  placeholder="blurred"
                  alt="Jasmin"
                />
              </div>
              <div className="entry-wrap">
                <div className="entry">
                  <h3 className="uppercase">Jasmin Brechbühl</h3>
                  <p>
                    Meine Grundausbildung zur Damen- und Herren-Coiffeuse habe
                    ich im Jahr 2007 abgeschlossen und im Jahr 2013 mit einer
                    Ausbildung zur Visagistin ergänzt. Ich liebe meine Arbeit
                    und gebe Tag für Tag das Beste für meine Kund*innen.
                    <br />
                    <br />
                    Im September 2015 habe ich mir mit der Gründung meines
                    eigenen Coiffeurgeschäfts einen Lebenstraum verwirklicht.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xs-12">
            <div className="entry-item card">
              <div className="entry-img">
                <StaticImage
                  src="./../images/team/anja.png"
                  placeholder="blurred"
                  alt="Anja"
                />
              </div>
              <div className="entry-wrap">
                <div className="entry">
                  <h3 className="uppercase">Anja Walthert</h3>
                  <p>
                    Ich bin 1984 geboren und komme aus Allmendingen, Thun. Ich
                    habe 10 Jahre auf meinem erlernten Beruf als Coiffeuse
                    gearbeitet, bis ich Mami und Hausfrau wurde. Jetzt freue ich
                    mich, wieder auf meinem Beruf zu arbeiten.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
